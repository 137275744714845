<template>
	<div>

		<!-- FORM MODAL -->
		<b-modal
			id="driver-requirement-form-view"
			title="Licencia Interna"
			size="lg"
			body-class="p-0"
		>

			<!-- CONTENT -->
			<b-overlay :show="loading" rounded="sm" class="p-3">
				<div class="card">
					<div v-if="motive != null" class="card-body pt-0">
						<p class="mb-1 text-danger fw-bold text-size-12">Especifique Motivo de Rechazo:</p>
						<textarea v-model="motive" class="form-control mb-2"></textarea>
						<button class="btn btn-danger mr-3" :disabled="motive == null || motive.length == 0" @click="reject()">Confirmar Rechazo</button>
						<button class="btn btn-outline-secondary" @click="motive = null">Cancelar</button>
					</div>
					<hr class="my-0">
					<div class="card-body bg-light">
						<p class="mb-0 fw-bold text-primary text-uppercase text-size-09">RUT</p>
						<p class="mb-2 text-size-13">{{ driving_license.data.identifier ? parseIdentifier(driving_license.data.identifier) : null }}</p>
						<p class="mb-0 fw-bold text-primary text-uppercase text-size-09">Nombre Completo</p>
						<p class="mb-2 text-size-13">{{ driving_license.data && driving_license.data.full_name ? driving_license.data.full_name : null }}</p>
						<p class="mb-0 fw-bold text-primary text-uppercase text-size-09">Empresa</p>
						<p class="mb-0 text-size-13">{{ driving_license.data && driving_license.data.company_name ? driving_license.data.company_name : null }}</p>
					</div>
					<hr class="my-0">
					<div class="card-body">
						<div class="alert alert-primary py-1 px-2 mb-2">
							<i class="fas fa-ban"></i>
							<span class="ml-2 fw-bold text-uppercase">Restricciones:</span>
						</div>
						<div class="row text-size-11">
							<div class="col-4">
								<p class="mb-0">¿Lentes?</p>
								<span v-if="driving_license.data.restrictions_glasses === true">
									<span class="text-primary mr-1">
										<i class="fas fa-scrubber"></i>
									</span>
									<span>Sí</span>
									<span class="text-secondary ml-4 mr-1">
										<i class="far fa-circle"></i>
									</span>
									<span>No</span>
								</span>
								<span v-else-if="driving_license.data.restrictions_glasses === false">
									<span class="text-secondary mr-1">
										<i class="far fa-circle"></i>
									</span>
									<span>Sí</span>
									<span class="text-primary ml-4 mr-1">
										<i class="fas fa-scrubber"></i>
									</span>
									<span>No</span>
								</span>
							</div>
							<div class="col">
								<p class="mb-0">¿Otras?</p>
								<p class="mb-0">
									<span v-if="driving_license.data.restrictions_other === true" class="text-primary fw-bold">{{ driving_license.data.restrictions_other_detail }}</span>
									<span v-else class="text-secondary">No</span>
								</p>
							</div>
						</div>
					</div>
					<hr class="my-0">
					<div class="card-body">
						<div class="alert alert-primary py-1 px-2">
							<i class="fas fa-id-card"></i>
							<span class="ml-2 fw-bold text-uppercase">Clases de Licencia Municipal:</span>
						</div>
						<div class="row mb-2 text-size-11">
							<div class="col-3" v-if="driving_license.data.classes.find(e => e === 1)">
								<span >
									<span class="text-primary mr-1">
										<i class="fas fa-check-square"></i>
									</span>
									<span>Clase A1</span>
								</span>
							</div>
							<div class="col-3" v-if="driving_license.data.classes.find(e => e === 2)">
								<span >
									<span class="text-primary mr-1">
										<i class="fas fa-check-square"></i>
									</span>
									<span>Clase A2</span>
								</span>
							</div>
							<div class="col-3" v-if="driving_license.data.classes.find(e => e === 3)">
								<span >
									<span class="text-primary mr-1">
										<i class="fas fa-check-square"></i>
									</span>
									<span>Clase A3</span>
								</span>
							</div>
							<div class="col-3" v-if="driving_license.data.classes.find(e => e === 4)">
								<span >
									<span class="text-primary mr-1">
										<i class="fas fa-check-square"></i>
									</span>
									<span>Clase A4</span>
								</span>
							</div>
							<div class="col-3" v-if="driving_license.data.classes.find(e => e === 5)">
								<span >
									<span class="text-primary mr-1">
										<i class="fas fa-check-square"></i>
									</span>
									<span>Clase A5</span>
								</span>
							</div>
							<div class="col-3" v-if="driving_license.data.classes.find(e => e === 6)">
								<span >
									<span class="text-primary mr-1">
										<i class="fas fa-check-square"></i>
									</span>
									<span>Clase B</span>
								</span>
							</div>
							<div class="col-3" v-if="driving_license.data.classes.find(e => e === 7)">
								<span >
									<span class="text-primary mr-1">
										<i class="fas fa-check-square"></i>
									</span>
									<span>Clase C</span>
								</span>
							</div>
							<div class="col-3" v-if="driving_license.data.classes.find(e => e === 8)">
								<span >
									<span class="text-primary mr-1">
										<i class="fas fa-check-square"></i>
									</span>
									<span>Clase D</span>
								</span>
							</div>
							<div class="col-3" v-if="driving_license.data.classes.find(e => e === 9)">
								<span >
									<span class="text-primary mr-1">
										<i class="fas fa-check-square"></i>
									</span>
									<span>Clase F</span>
								</span>
							</div>
						</div>
					</div>
					<hr class="my-0">
					<div class="card-body text-size-11">
						<div class="alert alert-primary py-1 px-2 text-size-09">
							<i class="fas fa-truck"></i>
							<span class="ml-2 fw-bold text-uppercase">Equipos:</span>
						</div>
						<div v-if="driving_license.data.equipments.find(e => e === 1)">
							<span >
								<span class="text-primary mr-1">
									<i class="fas fa-check-square"></i>
								</span>
								<span>Ambulancias (A2/A3)</span>
							</span>
						</div>
						<div v-if="driving_license.data.equipments.find(e => e === 2)">
							<span >
								<span class="text-primary mr-1">
									<i class="fas fa-check-square"></i>
								</span>
								<span>Buses (A3) - Ley Antigua (A1/A2)</span>
							</span>
						</div>
						<div v-if="driving_license.data.equipments.find(e => e === 3)">
							<span >
								<span class="text-primary mr-1">
									<i class="fas fa-check-square"></i>
								</span>
								<span>Camionetas (B)</span>
							</span>
						</div>
						<div v-if="driving_license.data.equipments.find(e => e === 4)">
							<span >
								<span class="text-primary mr-1">
									<i class="fas fa-check-square"></i>
								</span>
								<span>Carry All (A1/A2/A3)</span>
							</span>
						</div>
						<div v-if="driving_license.data.equipments.find(e => e === 5)">
							<span >
								<span class="text-primary mr-1">
									<i class="fas fa-check-square"></i>
								</span>
								<span>Camiones sobre 3.500 kgs. (A3)</span>
							</span>
						</div>
						<div v-if="driving_license.data.equipments.find(e => e === 6)">
							<span >
								<span class="text-primary mr-1">
									<i class="fas fa-check-square"></i>
								</span>
								<span>Camiones Pluma / Grúa (A5/D)</span>
							</span>
						</div>
						<div v-if="driving_license.data.equipments.find(e => e === 7)">
							<span >
								<span class="text-primary mr-1">
									<i class="fas fa-check-square"></i>
								</span>
								<span>Minibuses (A2/A3)</span>
							</span>
						</div>
						<div v-if="driving_license.data.equipments.find(e => e === 8)">
							<span >
								<span class="text-primary mr-1">
									<i class="fas fa-check-square"></i>
								</span>
								<span>Taxis (A1/A2/A3)</span>
							</span>
						</div>
						<div v-if="driving_license.data.equipments.find(e => e === 9)">
							<span >
								<span class="text-primary mr-1">
									<i class="fas fa-check-square"></i>
								</span>
								<span>Otros Equipos Licencia Clase D:</span>
							</span>
						</div>
						<div v-if="driving_license.data.equipments.find(e => e === 9)" class="pl-3">
							<span class="text-primary fw-bold">{{ driving_license.data.class_d_other }}</span>
						</div>
					</div>
					<hr class="my-0">
					<div class="card-body text-size-11">
						<div class="alert alert-primary py-1 px-2 text-size-09">
							<i class="fas fa-map-marker-alt"></i>
							<span class="ml-2 fw-bold text-uppercase">Áreas para conducir / operar:</span>
						</div>
						<div v-if="driving_license.data.areas.find(e => e === 1)">
							<span >
								<span class="text-primary mr-1">
									<i class="fas fa-check-square"></i>
								</span>
								<span>Área Administrativa y Caminos Principales</span>
							</span>
						</div>
						<div v-if="driving_license.data.areas.find(e => e === 2)">
							<span >
								<span class="text-primary mr-1">
									<i class="fas fa-check-square"></i>
								</span>
								<span>Área Planta (SX-EW, Lixiviación, Chancado, Ore Sorting)</span>
							</span>
						</div>
						<div v-if="driving_license.data.areas.find(e => e === 3)">
							<span >
								<span class="text-primary mr-1">
									<i class="fas fa-check-square"></i>
								</span>
								<span>Área Operaciones Mina</span>
							</span>
						</div>
						<div v-if="driving_license.data.areas.find(e => e === 4)">
							<span >
								<span class="text-primary mr-1">
									<i class="fas fa-check-square"></i>
								</span>
								<span>Cuesta Michilla</span>
							</span>
						</div>
					</div>
					
				</div>

				<!-- UPLOAD PROGRESS -->
				<template #overlay>
					<div class="text-center" style="width: 300px">
						<b-spinner type="grow" variant="primary" class="mb-4"></b-spinner>
						<b-progress :max="progress.total" height="5px" animated>
							<b-progress-bar :value="progress.current"></b-progress-bar>
						</b-progress>
					</div>
				</template>
				<!-- /UPLOAD PROGRESS -->

			</b-overlay>
			<!-- /CONTENT -->

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" class="mr-2" :disabled="loading">Cerrar</b-button>
			</template>
			<!-- /FOOTER -->

		</b-modal>
		<!-- /UPLOAD MODAL -->

	</div>
</template>

<script>
export default {
	name: 'DriverRequirementFormView',
	components: {
	},
	data() {
		return {
			loading: false,
			file: null,
			motive: null,
			expiration: '',
			creation: '',
			expires: true,
			progress: {
				current: 0,
				total: 0
			},
			options: [
				{ text: 'Sí, el documento vence.', value: true },
				{ text: 'No, el documento no vence (es indefinido).', value: false }
			],
			restrictions_other: false,
		}
	},
	props: {
		requirement: Object
	},
	computed: {
		api_root() {
			return this.$store.state.api_root
		},
		api_path() {
			return this.$store.state.api_path
		},
		requirements() {
			return this.$store.state.driver_requirements
		},
		owners() {
			return this.$store.state.workers
		},
		current() {
			return this.$store.getters.current_driver
		},
		driving_license() {
			return this.$store.getters.driving_license
		},
		/*
		store_index() {
			if (this.requirement.data.owner_id) {
				const index = this.owners.data.data.findIndex(owner => owner.id === this.requirement.data.owner_id)
				return index
			} else {
				return null
			}
		},
		*/
		val_creation() {
			if (this.creation.length > 0) {
				return true
			} else {
				return false
			}
		},
		val_file() {
			return this.file ? true : false // Boolean(this.file)
		},
		val_expiration() {
			if (this.expiration.length > 0) {
				return true
			} else {
				return false
			}
		},
		val_full() {
			return this.val_creation && this.val_expiration && this.val_file ? true : false
		},
		val_full_alt() {
			return this.driving_license.data.classes.length > 0 && this.driving_license.data.equipments.length > 0 && this.driving_license.data.areas.length > 0 ? true : false;
		},
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'driver-requirement-form-view') { this.reset() }
		})
	},
	methods: {
		parseIdentifier(i) {
			return i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1)
		},
		reset() {
			this.file = null
			this.creation = ''
			this.expiration = ''
			this.expires = true
			this.progress = { total: 0, current: 0 }
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				autoHideDelay: delay
			})
		},
		approve() {
			this.loading = true;
			this.$api.get('driving_licenses/'+this.driving_license.data.id+'/approve')
			.then(response => {
				this.$store.commit('save_driving_license', response.data);
				this.$bvModal.hide('driver-requirement-form-view');
				this.loading = false;
			})
			.catch(() => this.loading = false)
		},
		reject() {
			this.loading = true;
			this.$api.post('driving_licenses/'+this.driving_license.data.id+'/reject', {
				motive: this.motive,
			})
			.then(response => {
				this.motive = null;
				this.$store.commit('save_driving_license', response.data);
				this.$bvModal.hide('driver-requirement-form-view');
				this.loading = false;
			})
			.catch(() => this.loading = false)
		},
		toggleExpiration() {
			if (!this.expires) this.expiration = ''
		},
		is_new() {
			return true
		},
	}
}
</script>

<style scoped>
</style>
